import * as React from "react";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/header-one";
import Footer from "@layout/footer/footer-one";
import SectionOne from '@containers/Tutorials/PAKDD2021'
import Banner from "@components/banners/Ai";

const Ganblr = ({ pageContext, location }) => {
	return (
		<Layout location={location}>
			<Seo title="Tabs" />
			<Header />
			<Banner
				pageContext={pageContext}
				location={location}
				title="Harnessing the Power of Generative Adversarial Networks Style Learning for Tabular Data Generation"
			/>
			<main className="site-wrapper-reveal">
				<SectionOne />
			</main>
			<Footer />
		</Layout>
	)
}

export default Ganblr
